<script>
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header.vue";
  import DataLoader from "@/components/data-loader.vue";
  import TransactionTableCard from "@/components/dashboard/transaction/transaction-table-card.vue";
  import API from "@/plugins/axios-method";
  import Pagination from "@/components/dashboard/Pagination.vue";

  export default {
    components: {Pagination, TransactionTableCard, DataLoader, PageHeader, Layout},
    data(){
      return {
        showDataLoader : true,
        transactions: [],
        startDate: '',
        endDate: '',
        next: null,
        previous: null,
        page: 1,
        totalItems: 0,
        url: ''
      }
    },
    mounted() {
      this.initDate()
      this.getTransactions()
    },
    methods: {
      initUrl(){
        this.url = '/accounts/get_transactions/dashboard?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + this.page
      },
      initDate(){
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()

        this.startDate = year + '-01-01'
        this.endDate = year + '-' + month + '-' + day

        this.initUrl()
      },
      setPagination(response){
        if (response.data["hydra:view"]["hydra:next"]) {
          this.totalItems = response.data["hydra:totalItems"]
          this.next = response.data["hydra:view"]["hydra:next"]
          this.previous = response.data["hydra:view"]["hydra:previous"]
        }
      },
      getTransactions(){
        API.get(this.url)
            .then(response => {
              if (response.status === 200){
                response.data["hydra:member"].map(t =>{
                  this.transactions.push(t)
                })
                this.setPagination(response)
                this.showDataLoader = false
              }
            })
            .catch(error => {
              console.log(error)
            })
      },
      nextPage(){
        this.setCurrentPage(this.next)
        this.transactions = []
        this.getTransactions()
      },
      previousPage(){
        this.setCurrentPage(this.previous)
        this.transactions = []
        this.getTransactions()
      },
      navigate(page){
        this.page = page
        this.transactions = []
        this.showDataLoader = true
        this.initUrl()
        this.getTransactions()
      },
      setCurrentPage(url){
        const currentPage = Number(url.split('page=')[1])
        if (currentPage !== this.page) this.page = currentPage
        this.url = url.split('api/')[1]
        this.showDataLoader = true
      },
      handleFilter(date){
        this.showDataLoader = true
        this.startDate = date.startDate
        this.endDate = date.endDate
        this.initUrl()
        this.initNavigation()
        this.transactions = []
        this.getTransactions()
      },
      initNavigation(){
        this.next = null
        this.previous = null
      }
    }

  }
</script>

<template>
  <Layout>
    <PageHeader
        :show-add-button="false"
        :show-search="false"
        :title="'Transactions entrantes'"
        :show-date-filter="true"
        @submitDateFilter="handleFilter"
    />
    <DataLoader v-if="showDataLoader"/>
    <div class="card" v-if="transactions.length > 0 && !showDataLoader">
      <div class="card-body">
        <TransactionTableCard
            :transactions="transactions"
        />
        <Pagination
            :next="next"
            :previous="previous"
            :currentPage="page"
            :totalItems="totalItems"
            @navigate="navigate"
            @nextPage="nextPage"
            @previousPage="previousPage"
        />
      </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>